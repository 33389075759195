import styled from "@emotion/styled"
import Img from "gatsby-image"

export const Avatar = styled(Img)`
  border-radius: 100%;
  margin: 0 auto;
  display: block !important;

  max-width: 20rem;
`
